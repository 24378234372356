<template>
  <Layout>
    <WithLocation
      v-slot="{ isLocationLoading, theLocation, theFeaturedImage }"
      :slug="locSlug"
    >
      <BaseLoader v-if="isLocationLoading" bg-color="#fff" color="#6a8ed1"
        ><h3 class="text-xs-center mt-4">Loading the menu...</h3></BaseLoader
      >
      <div v-else-if="!theLocation" class="not-found">
        <BasePageSection bg-color="#6a8ed1">
          <VLayout justify-center>
            <VFlex xs6>
              <h3 class="r-headline"
                >Sorry, but we couldn't find that location. Pick one below or
                <BaseButton
                  flat
                  link
                  small
                  color="green darken-2"
                  to="/contact-team"
                  >get in touch</BaseButton
                >
                if you need assistance.</h3
              >
            </VFlex>
          </VLayout>
        </BasePageSection>
      </div>
      <div v-else class="single-location">
        <BaseThemeComponent
          name="SingleLocationHeader"
          :bg-image="theFeaturedImage ? theFeaturedImage : ''"
          :title="theLocation.title"
          :location="theLocation"
        />
        <BasePageSection
          v-if="theLocation.status !== 'coming_soon'"
          id="menu"
          py-2
          fluid
          full-width
          class="treps-menu-section"
        >
          <WithLayout
            v-for="slug of layouts"
            v-slot="{ isLayoutLoading, theLayout }"
            :key="slug"
            :slug="slug"
          >
            <LayoutGrid
              v-if="!isLayoutLoading"
              :ref="`grid-${slug}`"
              :location="theLocation"
              :layout="theLayout"
              class="treps-web-menu"
              @doneLoading="gridLoaded(theLayout)"
            />
          </WithLayout>
        </BasePageSection>
        <BaseThemeComponent name="ReservationCTA" />
      </div>
    </WithLocation>
    <BaseMenuComponent name="MenuSnackbar" />
  </Layout>
</template>

<script>
import Layout from '@utils/get-layout'
import formatPhone from '@utils/format-phone'
import LayoutGrid from '@components/Layout/LayoutGrid'

export default {
  name: 'TrepsSingleLocation',
  components: { Layout, LayoutGrid },
  props: {
    locSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEmailModal: false,
      loaded: 0,
      layouts: ['treps-food-menu', 'treps-drinks-menu'],
    }
  },
  computed: {
    hasLoadedAllLayouts() {
      return this.loaded >= this.layouts.length
    }, // hasLoadedAllLayouts
    scrollToSection() {
      let slugMatch = this.layouts.find((slug) => `#${slug}` === location.hash)
      return slugMatch && this.hasLoadedAllLayouts
        ? this.$refs[`grid-${slugMatch}`][0].$el
        : false
    }, // scrollToSection
  },
  methods: {
    formatPhone,
    excludeThisLocation(loc) {
      return loc.slug !== this.$route.params.locSlug
    }, // excludeThisLocation

    gridLoaded(layout) {
      this.loaded++
      if (this.scrollToSection && this.hasLoadedAllLayouts) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$scrollTo(this.scrollToSection, 500, { offset: -100 })
          }, 300)
        })
      }
    }, // gridLoaded
  },
}
</script>

<style lang="scss">
@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}

.treps-web-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. title title .' '. left center .' '. right . .' !important;
      grid-template-columns: 1fr 6fr 6fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'title title' 'left center' 'right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'title' 'left' 'center' 'right' !important;
      grid-template-columns: 1fr !important;
      .region {
        margin: 0 !important;
      }
    }
  }
  .layout--title {
    text-align: center;
    text-transform: uppercase;
  }
}

.treps-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }

  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
