var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('WithLocation',{attrs:{"slug":_vm.locSlug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLocationLoading = ref.isLocationLoading;
var theLocation = ref.theLocation;
var theFeaturedImage = ref.theFeaturedImage;
return [(isLocationLoading)?_c('BaseLoader',{attrs:{"bg-color":"#fff","color":"#6a8ed1"}},[_c('h3',{staticClass:"text-xs-center mt-4"},[_vm._v("Loading the menu...")])]):(!theLocation)?_c('div',{staticClass:"not-found"},[_c('BasePageSection',{attrs:{"bg-color":"#6a8ed1"}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs6":""}},[_c('h3',{staticClass:"r-headline"},[_vm._v("Sorry, but we couldn't find that location. Pick one below or "),_c('BaseButton',{attrs:{"flat":"","link":"","small":"","color":"green darken-2","to":"/contact-team"}},[_vm._v("get in touch")]),_vm._v(" if you need assistance.")],1)])],1)],1)],1):_c('div',{staticClass:"single-location"},[_c('BaseThemeComponent',{attrs:{"name":"SingleLocationHeader","bg-image":theFeaturedImage ? theFeaturedImage : '',"title":theLocation.title,"location":theLocation}}),(theLocation.status !== 'coming_soon')?_c('BasePageSection',{staticClass:"treps-menu-section",attrs:{"id":"menu","py-2":"","fluid":"","full-width":""}},_vm._l((_vm.layouts),function(slug){return _c('WithLayout',{key:slug,attrs:{"slug":slug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLayoutLoading = ref.isLayoutLoading;
var theLayout = ref.theLayout;
return [(!isLayoutLoading)?_c('LayoutGrid',{ref:("grid-" + slug),refInFor:true,staticClass:"treps-web-menu",attrs:{"location":theLocation,"layout":theLayout},on:{"doneLoading":function($event){return _vm.gridLoaded(theLayout)}}}):_vm._e()]}}],null,true)})}),1):_vm._e(),_c('BaseThemeComponent',{attrs:{"name":"ReservationCTA"}})],1)]}}])}),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }